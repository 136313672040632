.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9); /* Light background for readability */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  color: #000; /* Text color for readability */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ant-dark-mode .custom-tooltip {
  background-color: rgba(0, 0, 0, 0.9); /* Dark background for dark mode */
  color: #fff; /* White text color for dark mode */
}

.selected-row {
  background-color: rgba(224, 224, 224, 0.23) !important; /* Change this color to your desired highlight color */
}