.codeBlockContainer {
  position: relative;
  // background-color: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
  margin-top: 10px;
  overflow: hidden;
}

.codeBlock {
  font-family: 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre;
  overflow-x: auto;
  margin: 0;
  padding-bottom: 16px;
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.copyButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #1890ff;
  z-index: 1;

  &:hover {
    color: #40a9ff;
  }
}
