.topBar {
  // cursor: pointer;

  // &:hover {
  //   background-color: rgba(165, 54, 54, 0.02);
  // }

  .row {
    justify-content: space-between;
    align-items: middle;
  }

  .col {
    display: flex;
    align-items: center;

    &.autoFlex {
      flex: auto;
      justify-content: flex-end;
    }

    &.noneFlex {
      flex: none;
    }
  }

  .menuButton {
    margin-right: 16px;
  }

  .logo {
    margin-right: 16px;
  }

  .themeSwitch {
    margin-left: 16px;
    display: flex;
    align-items: center;

    &.dark {
      background: #1890ff;
    }

    &.light {
      background: #c1c1c1;
    }
  }

  .notificationsWrapper {
    margin-left: 16px;
  }

  .userProfileWrapper {
    margin-left: 16px;
  }
}

table {
  font-size: large;
}
