.setupWizard {
  :global {
    .ant-alert-success {
      background-color: var(--ant-success-color-bg);
      border-color: var(--ant-success-color-border);
    }

    html[data-theme='dark'] & {
      .ant-alert-success {
        background-color: transparent;
        border-color: var(--ant-success-color-border);
      }
    }

    .ant-alert {
      margin-top: 16px;
      
      .anticon {
        &.anticon-check-circle {
          color: var(--ant-success-color);
        }
      }
    }
  }
}
