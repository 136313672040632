.EncryptionKeys {


    .icon {
        padding: 5px 10px;
    }

    .icon:hover {
        transform: rotate(45deg);
        transition: all 0.25s ease-in-out;
    }
}

.icon {
    padding: 5px 10px;
}

.icon:hover {
    transform: rotate(45deg);
    transition: all 0.25s ease-in-out;
}
