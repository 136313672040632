:root {
  --ant-font-size-base: 18px;
  --ant-layout-sider-background: #141414; /* Customize the sidebar background */
  --ant-layout-header-background: #141414; /* Customize the header background */
}

body {
  background-color: var(--ant-color-bg-base); /* Adjusted based on theme */
  font-size: var(--ant-font-size-base);
  font-family: "Darker Grotesque", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ant-layout .ant-layout-sider {
  background-color: var(--ant-component-background);
}

.ant-layout-header {
  background-color: inherit;
}

.ant-layout .ant-layout-sider-trigger {
  color: #3983dc;
  background-color: inherit;
}

.ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-12ayeyb).ant-table-wrapper .ant-table-thead >tr>td {
    position: relative;
    background-color: inherit;
    font-weight: 600;
    text-align: start;
    font-size: medium;
}

.ant-table-wrapper .ant-table {
  font-size: medium;
}

.view-appear, .view-enter {
  /* opacity: 0; */
  /* remove or lessen the blur */
  /* filter: blur(5px);  */
}

.view-appear-active, .view-enter-active {
  /* opacity: 1;
  filter: none;
  transition: opacity 300ms ease; */
}

.tab-layout {
  display: flex;
  height: 100%;  /* Adjust based on your layout needs */
}

/* Styling for the tabs container if needed */
.ant-tabs-left {
  flex: 0 0 200px; /* Adjust width as needed */
}

/* Styling for the content area */
.tab-content {
  flex-grow: 1;
  padding: 16px; /* Optional padding */
}

.ant-tag {
  font-size: large;
}

.ant-col {
  font-size: large;
}

.cellWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.cellTag {
  flex: 1 1 25%;
  margin-bottom: 4px;
}

/* Table row styles */
.table-row {
  cursor: pointer; /* Make the row cursor a pointer to indicate it's clickable */
  transition: background-color 0.3s; /* Smooth transition for background color change */
}

.table-row:hover {
  background-color: #f5f5f5; /* Change background color on hover */
}

/* Style for selectable text */
.selectable {
  user-select: text; /* Allow text selection */
  cursor: text; /* Change cursor to text */
}




/* Table row styles */
.table-row {
  cursor: pointer; /* Make the row cursor a pointer to indicate it's clickable */
  transition: background-color 0.3s; /* Smooth transition for background color change */
}

.table-row:hover {
  background-color: #f5f5f5; /* Change background color on hover */
}

/* Style for selectable text */
.selectable {
  user-select: text; /* Allow text selection */
  cursor: text; /* Change cursor to text */
}


.ant-layout-sider-trigger {
  display: none !important;
}