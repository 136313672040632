.PublicLayout {
  height: 100%;
  margin: auto;
  position: absolute;
  // top: 0;
  left: 0;
  // bottom: 0;
  right: 0;
  width: 35em;
}

div.errorMessage {
  margin-top: 2em;
}

.login-form {
  width: 10em;
}
.LoginFormForgot {
  left: 0;
}
.LoginFormRegister {
  left: 0;
}

.LoginButton {
  width: 100%;
}

.logoAsText {
  padding: 24px;
  text-align: center;
  font-family: "Darker Grotesque";
  font-size: xx-large;
  margin: 0px;
  color: #6b6b6b;
}
