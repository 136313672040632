.sticky-sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: 100;
}

@media (max-width: 768px) {
  .ant-layout-sider {
    position: fixed;
    height: 100vh;
    z-index: 1000;
  }
}

.ant-layout .ant-layout-sider-zero-width-trigger {
  inset-inline-end: -20px;
  background: var(--ant-component-background);
  color: #4096ff;
  border: var(--border-width-base) solid var(--border-color-split);
  
  width: 20px;
  height: 35px;
  font-size: 16px;
}