.notificationBadge {
  cursor: pointer;
  
  :global(.ant-badge-count) {
    background-color: rgba(250, 173, 20, 0.85);
  }

  .notificationIcon {
    font-size: 20px;
    cursor: pointer;
  }
}

.notificationItem {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 8px;

  .titleSection {
    display: flex;
    align-items: center;
    gap: 8px;

    .title {
      font-weight: bold;
    }
  }

  .messageSection {
    margin-top: 4px;

    .timestamp {
      font-size: 12px;
      color: #888;
      margin-top: 4px;
    }
  }

  .actionSection {
    text-align: left;
    margin-top: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.loadingContainer {
  text-align: center;
  padding: 24px 0;
}
