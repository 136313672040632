.Login {
  height: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 30em;
  font-family: "Darker Grotesque";
}
div.errorMessage {
  margin-top: 2em;
}

.login-form {
  width: 10em;
}
.LoginFormForgot {
  left: 0;
}
.LoginFormRegister {
  left: 0;
}

.LoginFormGoogle {
  cursor: pointer;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  text-align: left;
  width: 100%;
  border-radius: 3px;
  margin: 10px auto;
  padding-left: 20%;
}

.LoginButton {
  width: 100%;
}

.logoAsText {
  padding: 24px;
  text-align: center;
  font-family: "Darker Grotesque";
  font-size: xx-large;
  margin: 1px;
  color: #6b6b6b;
}

.googleConnect {
  background: rgb(255, 255, 255) url('https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA') no-repeat scroll 25px 7px / 35px 35px padding-box border-box;
  border: 1px solid rgba(218, 218, 218, 0.856);
}

.googleConnect:hover {
  border-color: rgb(199, 199, 199);
  background: rgb(174, 174, 174) url('https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA') no-repeat scroll 25px 7px / 35px 35px padding-box border-box;
}


.googleConnect span {
  box-sizing: border-box;
  color: rgb(200, 200, 200);
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border: 0px none rgba(178, 215, 245, 0.763);
  outline: rgb(255, 255, 255) none 0px;
}

.googleConnect:hover span {
  color: #FFF;
}