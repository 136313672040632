.Events {
    font-family: "Darker Grotesque";

    .ant-tag {
        font-size: medium;
    }



  
//   :global(.ant-layout-header) {
//     padding: 8px; /* Add padding to the cells */
//   }
  
  
  .cellContent {
    position: relative;
  }
  
  .cellContent:hover .hoverIcon {
    opacity: 1;
  }
  
   .hoverIcon {
    position: absolute;
    right: 8px;
    opacity: 0; /* Start with fully transparent */
    transition: opacity 0.3s;
    cursor: pointer;
  }
  
  .hoverIconTableColumns {
    position: relative;
    margin-left: 12px;
    // padding: 4px;
    opacity: 0; /* Start with semi-transparent */
    transition: opacity 0.3s;
    cursor: pointer;
    vertical-align: middle;
  }
  
  .columnHeader:hover .hoverIconTableColumns {
    opacity: 1; /* Fully opaque on hover */
  }
  
  .columnHeader {
    display: flex;
    align-items: right; /* Center items vertically */
    // padding: 8px; /* Add some padding around the content */
    /* cursor: pointer; */
    position: relative;
  }
  
  .cellContent {
    display: flex;       /* Establishes a flex container */
    align-items: center; /* Vertically aligns flex children in the middle */
    justify-content: start; /* Aligns children to the start of the container */
    vertical-align: middle;
  }
  
  .custom-drawer-style .antDrawerBody {
    padding: 0;
  }
  
  .div .ant-drawer-body {
    padding: 0;
  }
  
  .custom-drawer-style .ant-drawer-body {
    position: relative;
    padding: 48px;
    overflow: hidden;
    text-align: center;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 2px;
  }


  .expandable-table .ant-table-cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }


}